.rotate-out-ver {
    -webkit-animation: rotate-out-ver 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation        : rotate-out-ver 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.rotate-in-ver {
    -webkit-animation: rotate-in-ver 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation        : rotate-in-ver 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes rotate-out-ver {
    0% {
        -webkit-transform: rotateY(360deg);
        transform        : rotateY(360deg);
        opacity          : 1;
    }

    100% {
        -webkit-transform: rotateY(0deg);
        transform        : rotateY(0deg);
        opacity          : 0;
    }
}

@keyframes rotate-out-ver {
    0% {
        -webkit-transform: rotateY(360deg);
        transform        : rotateY(360deg);
        opacity          : 1;
    }

    100% {
        -webkit-transform: rotateY(0deg);
        transform        : rotateY(0deg);
        opacity          : 0;
    }
}

@-webkit-keyframes rotate-in-ver {
    0% {
        -webkit-transform: rotateY(-360deg);
        transform        : rotateY(-360deg);
        opacity          : 0;
    }

    100% {
        -webkit-transform: rotateY(0deg);
        transform        : rotateY(0deg);
        opacity          : 1;
    }
}

@keyframes rotate-in-ver {
    0% {
        -webkit-transform: rotateY(-360deg);
        transform        : rotateY(-360deg);
        opacity          : 0;
    }

    100% {
        -webkit-transform: rotateY(0deg);
        transform        : rotateY(0deg);
        opacity          : 1;
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: translate(0, 0);
        transform        : translate(0, 0);
    }

    50% {
        -webkit-transform: translate(0, 10px);
        transform        : translate(0, 10px);
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform        : translate(0, 0);
    }
};

@keyframes shake {
  0% {
      transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
      transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
      transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
      transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
      transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
      transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
      transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
      transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
      transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
      transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
      transform: translate(1px, -2px) rotate(-1deg);
  }
}