@import './vars.scss';

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin            : 0;
}

.error-border-table-row {
    outline    : 1px solid $auxiliary-danger !important;
    border: 1px solid $auxiliary-danger !important;
}

.error-border-input {
    border: 1px solid $auxiliary-danger !important;
}

.input-label {
    font-size: 14px;
    line-height: 17px;
    color: $text-trueWhite;
    margin-bottom: 5px;
    margin-top: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay        : 9999s;
}