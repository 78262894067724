@import '../../../assets/styles/vars.scss';

.packs-slider {
	.carousel-root {
		width: 85vw;
		margin: 0 auto;
		
		@media screen and (max-width: 1280px) {
			.carousel {
				display: flex;
				flex-direction: column-reverse;
			
				.control-dots {
					position: inherit;
					margin-top: 20px;
	
					.dot.selected {
						background: $primary;
					}
				}
			}
		}
	}
}