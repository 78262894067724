@import 'atropos/atropos.scss';
@import 'assets/styles/vars.scss';
@import 'assets/styles/forms.scss';
@import 'assets/styles/fonts.scss';
@import 'assets/styles/animations.scss';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $background-main !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

hr {
	border: 1px solid $border-main !important;
	margin: 2rem !important;
}

*::-webkit-scrollbar {
	height: 7px;
	width: 7px;
}

*::-webkit-scrollbar-track {
	background   : #555;
}

*::-webkit-scrollbar-thumb {
	background   : rgb(34, 34, 34);
	border-radius: 7px;
}

.display-flex {
	display: flex;
}

.flex-grow-full {
	flex-grow: 1 !important;
}

.align-items-end {
	align-items: flex-end;
}

.align-items-center {
	align-items: center;
}

.align-self-center {
	align-self: center;
}

.justify-content-end {
	justify-content: flex-end;
}

.justify-content-center {
	justify-content: center;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.text-align-left {
	text-align: left;
}

.carousel .slide {
	text-align: inherit !important;
}

.panel-fill {
	background: $background-panel;
	border    : 1px solid $border-main;
	border-radius: 3px;
}

.margin-auto {
	margin: auto !important;
}

.max-width-100 {
	max-width: 100% !important;
}

.cursor-pointer {
	cursor: pointer;
}

.display-none {
	display: none;
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.ml-0 {
	margin-left: 0 !important;
}

.mt-0 {
	margin-top: 0 !important;
}