$primary: #00E7AE;
$secondary: #13161F;
$background-main: #070A14;
$background-panel: #0C1120;
$background-hoverMain: #13161F;
$border-main: #1E222D;
$border-panel: #1F2431;
$icons-main: #3E414F;
$auxiliary-success: #39C700;
$auxiliary-danger: #E11501;
$auxiliary-warning: #D6B313;
$auxiliary-grey: #12192E;
$text-trueWhite: #FFFFFF;
$text-greyStuned: #414553;
$text-greyDetails: #434654;
$text-lightOn: #767D92;