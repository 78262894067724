@import '../../../assets/styles/vars.scss';

.section-header {
    margin: 24px 0;

    hr {
        border: 0 !important;
        height: 1px;
        width: 100%;
        background: linear-gradient(to right, $primary 88px, $border-main 88px, $border-main 100%);
        margin: 10px 0 !important;
    }

    h4 {
        font-family: Klavika;
        font-style : normal;
        font-weight: bold;
        font-size  : 16px;
        line-height: 19px;
        color: $text-trueWhite;
        margin: 0;
        text-transform: uppercase;
    }

    p {
        font-family: Klavika;
        font-style : normal;
        font-weight: 500;
        font-size  : 13px;
        line-height: 16px;
        color: $text-greyStuned;
        margin-top: 0;
    }
}