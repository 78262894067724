.atropos-banner {
    width: 100%;
}

.atropos-inner {
    margin: auto;
}

.atropos-banner .atropos-inner {
    border-radius: 10px;
}

.atropos-banner .card-img {
    position          : absolute;
    left              : 0%;
    top               : 0%;
    display           : block;
    z-index           : 1;
    transform-style   : preserve-3d;
    pointer-events    : none;
}

.atropos-banner img.atropos-banner-spacer {
    position     : relative;
    left         : 0;
    top          : 0;
    visibility: hidden;
}

.atropos-banner .atropos-shadow {
    filter : blur(50px);
    opacity: 0.5;
}

.atropos-banner .atropos-highlight {
    z-index: 100;
}