@import '../../../assets/styles/vars.scss';

.statistic-chart {
    background: $background-panel;
    border    : 1px solid $border-main;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 20px;

    canvas {
        max-height: 350px;
        max-width: 100%;
    }
}